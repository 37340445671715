import cleanDeep from 'clean-deep';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { useQueryParams } from 'use-query-params';

import { queryParams } from '@/blocks/player-search-result-block/queryParams';
import {
  Card,
  CardCloseButton,
  CardOptions,
  CountryField,
  DateField,
  DateTimeField,
  EmailField,
  Form,
  IPAddressField,
  NumberField,
  PhoneNumberField,
  SelectField,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import {
  AccountStatusEnum,
  BrandEnum,
  RegistrationStatusFilter,
} from '@/globalTypes';
import { AdvancedPlayerFormQuery } from './__generated__/AdvancedPlayerFormQuery';

const query = graphql`
  query AdvancedPlayerFormQuery {
    sanityAdvancedPlayerSearchForm {
      title {
        ...LocaleString
      }
      firstName {
        ...LocaleString
      }
      lastName {
        ...LocaleString
      }
      phoneNumber {
        ...LocaleString
      }
      email {
        ...LocaleString
      }
      minBalance {
        ...LocaleString
      }
      maxBalance {
        ...LocaleString
      }
      birthDate {
        ...LocaleString
      }
      countryCode {
        ...LocaleString
      }
      lastLoginIp {
        ...LocaleString
      }
      brand {
        ...LocaleString
      }
      accountStatus {
        ...LocaleString
      }
      registrationStatus {
        ...LocaleString
      }
      ssn {
        ...LocaleString
      }
      registeredIp {
        ...LocaleString
      }
      registeredIpCountryCode {
        ...LocaleString
      }
      lastLoginFrom {
        ...LocaleString
      }
      lastLoginTo {
        ...LocaleString
      }
      lastLoginIpCountryCode {
        ...LocaleString
      }
      street {
        ...LocaleString
      }
      city {
        ...LocaleString
      }
      zipCode {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const AdvancedPlayerSearchForm: FC = () => {
  const data = useStaticQuery<AdvancedPlayerFormQuery>(query);
  const [defaultValues, setQuery] = useQueryParams(queryParams);

  const form = data.sanityAdvancedPlayerSearchForm;

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onFilter = (data: typeof defaultValues) => {
    const clean = cleanDeep(data);
    // Todo: Find a way to use pushIn with empty values, as this
    // sadly removes all the old values (order, desc, etc.)
    setQuery(clean, 'push');
    if (close) {
      close();
    }
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title="Advanced Search"
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onFilter}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <TextField
            title={t(form.firstName)}
            name="firstName"
            id="AdvancedPlayerSearchForm__firstName"
            autoFocus
          />
          <TextField
            title={t(form.lastName)}
            name="lastName"
            id="AdvancedPlayerSearchForm__lastName"
          />
          <PhoneNumberField
            title={t(form.phoneNumber)}
            name="phoneNumber"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <EmailField
            title={t(form.email)}
            name="email"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <NumberField
            title={t(form.minBalance)}
            name="minBalance"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <NumberField
            title={t(form.maxBalance)}
            name="maxBalance"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <DateField
            title={t(form.birthDate)}
            name="birthDate"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <CountryField
            title={t(form.countryCode)}
            name="countryCode"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <IPAddressField
            title={t(form.lastLoginIp)}
            name="lastLoginIp"
            id="AdvancedPlayerSearchForm__phoneNumber"
          />
          <SelectField
            title={t(form.brand)}
            name="brand"
            id="AdvancedPlayerSearchForm__phoneNumber"
            options={Object.keys(BrandEnum).map((value) => ({
              value,
              label: value,
            }))}
          />
          <SelectField
            title={t(form.accountStatus)}
            name="accountStatus"
            id="AdvancedPlayerSearchForm__phoneNumber"
            options={Object.keys(AccountStatusEnum).map((value) => ({
              value,
              label: value,
            }))}
          />
          <SelectField
            title={t(form.registrationStatus)}
            name="registrationStatus"
            id="AdvancedPlayerSearchForm__phoneNumber"
            options={Object.keys(RegistrationStatusFilter).map((value) => ({
              value,
              label: value,
            }))}
          />
          <TextField
            title={t(form.ssn)}
            name="ssn"
            id="AdvancedPlayerSearchForm__ssn"
          />
          <TextField
            title={t(form.registeredIp)}
            name="registeredIp"
            id="AdvancedPlayerSearchForm__registeredIp"
          />
          <CountryField
            title={t(form.registeredIpCountryCode)}
            name="registeredIpCountryCode"
            id="AdvancedPlayerSearchForm__registeredIpCountryCode"
          />
          <DateTimeField
            title={t(form.lastLoginFrom)}
            name="lastLoginFrom"
            id="AdvancedPlayerSearchForm__lastLoginFrom"
          />
          <DateTimeField
            title={t(form.lastLoginTo)}
            name="lastLoginTo"
            id="AdvancedPlayerSearchForm__lastLoginTo"
          />
          <IPAddressField
            title={t(form.lastLoginIpCountryCode)}
            name="lastLoginIpCountryCode"
            id="AdvancedPlayerSearchForm__lastLoginIpCountryCode"
          />
          <TextField
            title={t(form.street)}
            name="street"
            id="AdvancedPlayerSearchForm__street"
          />
          <TextField
            title={t(form.city)}
            name="city"
            id="AdvancedPlayerSearchForm__city"
          />
          <TextField
            title={t(form.zipCode)}
            name="zipCode"
            id="AdvancedPlayerSearchForm__zipCode"
          />

          <SubmitButton value={t(form.submit)} />
        </Form>
      </div>
    </Card>
  );
};

export default AdvancedPlayerSearchForm;
