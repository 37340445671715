import {
  BooleanParam,
  DateParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { PlayerSearchOrder } from '@/globalTypes';
import { enumParam } from '@/utils/query/params';
import {
  AccountStatusParam,
  BrandParam,
  RegistrationStatusFilterParam,
} from '@/utils/query/params';

const PlayerSearchOrderParam = enumParam(PlayerSearchOrder);

export const queryParams = {
  id: StringParam,
  email: StringParam,
  brand: BrandParam,
  firstName: StringParam,
  lastName: StringParam,
  before: StringParam,
  after: StringParam,
  birthDate: DateParam,
  countryCode: StringParam,
  phoneNumber: StringParam,
  registrationStatus: RegistrationStatusFilterParam,
  ssn: StringParam,
  registeredIp: StringParam,
  registeredIpCountryCode: StringParam,
  lastLoginFrom: DateTimeParam,
  lastLoginTo: DateTimeParam,
  lastLoginIp: StringParam,
  lastLoginIpCountryCode: StringParam,
  street: StringParam,
  city: StringParam,
  zipCode: StringParam,
  minBalance: NumberParam,
  maxBalance: NumberParam,
  accountStatus: AccountStatusParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(PlayerSearchOrderParam, PlayerSearchOrder.registeredAt),
};
